import { T } from "@transifex/react";
import { useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import { FaPlusCircle } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import AddPlaylistsModal from "./AddPlaylistsModal";
import style from "./Screens.module.css";
import ScreensAccordionActions from "./ScreensAccordionActions";
import { t } from "@transifex/native";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import noimage from "./../../Assets/no-image.jpg";

function ScreensAccordion(props) {
  const [checkedGroups, setcheckedGroups] = useState([]);
  const [checkedGroupsData, setCheckedGroupsData] = useState([]);
  const [modalShow, setmodalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const modalHandleClose = () => setmodalShow(false);
  const modalHandleShow = () => setmodalShow(true);
  const history = useHistory();

  const groupswithscreens = [];
  props.screendata.forEach((group, groupindex) => {
    const screenwithsametitle = [];
    group.screens.forEach((screen, index) => {
      if (!screenwithsametitle[screen.screen_title]) {
        screenwithsametitle[screen.screen_title] = [];
      }
      screenwithsametitle[screen.screen_title][screen.screen_id] = screen;
    });
    group["arrangescreens"] = screenwithsametitle;
  });

  let userDetails = localStorage.getItem("userdetails");
  let loggedInUserID = "";
  if (userDetails === null) {
    loggedInUserID = 1;
  } else {
    loggedInUserID = JSON.parse(userDetails).id;
  }

  const notify = (val) => {
    toast.error(val, {
      toastId: loggedInUserID,
    });
  };

  // Handle group selection
  const handleCheckedGroups = (clickedgroupId, event) => {
    // Handle group checkbox
    const groupsChecked = [...checkedGroups];
    if (groupsChecked.includes(clickedgroupId)) {
      var index = groupsChecked.indexOf(clickedgroupId);
      groupsChecked.splice(index, 1);
    } else {
      groupsChecked.push(clickedgroupId);
    }
    setcheckedGroups(groupsChecked);
    // Add/Remove group and screens id
    const checkedboxesData = [...checkedGroupsData];
    if (event.target.checked) {
      var checkedScreensGroups = [];
      checkedboxesData.forEach((group, index) => {
        checkedScreensGroups.push(group.groupid);
      });
      const innerScreens = [];
      const deviceTypes = [];
      const cpIDs = [];
      props.screendata.forEach((group, index) => {
        if (group.groupid == clickedgroupId) {
          // Remove aready selected screens and add all the screens in the group
          if (checkedScreensGroups.includes(clickedgroupId)) {
            var groupindex = checkedboxesData.indexOf(clickedgroupId);
            checkedboxesData.splice(groupindex, 1);
          }
          group.screens.forEach((screen, index) => {
            innerScreens.push(screen.screen_id);
            deviceTypes.push(screen.device_type);
            cpIDs.push(screen.cpid);
          });
        }
      });
      checkedboxesData.push({
        groupid: clickedgroupId,
        screens: innerScreens,
        deviceTypes: deviceTypes,
        cpIDs: cpIDs,
      });
    } else {
      checkedboxesData.forEach((group, index) => {
        if (group.groupid === clickedgroupId) {
          checkedboxesData.splice(index, 1);
        }
      });
    }
    setCheckedGroupsData(checkedboxesData);
    return true;
  };

  // Handle screen selection
  const handleCheckedScreens = (
    clickedScreenId,
    deviceType,
    clickedgroupId,
    event
  ) => {
    const groupId = clickedgroupId.toString();
    // Add/Remove group and screens id
    const checkedboxesData = [...checkedGroupsData];
    var checkedScreensGroups = [];
    checkedboxesData.forEach((group, index) => {
      checkedScreensGroups.push(group.groupid);
    });

    if (event.target.checked === true) {
      if (checkedScreensGroups.includes(groupId)) {
        checkedboxesData.forEach((group, index) => {
          if (group.groupid === groupId) {
            const checkedGroupScreens = [...group.screens];
            checkedGroupScreens.push(clickedScreenId);
            group.screens = checkedGroupScreens;
            group.deviceTypes = [...group.deviceTypes, deviceType];
          }
        });
      } else {
        const innerScreens = [];
        innerScreens.push(clickedScreenId);
        checkedboxesData.push({
          groupid: groupId,
          screens: innerScreens,
          deviceTypes: [deviceType],
        });
      }
    } else {
      checkedboxesData.forEach((group, index) => {
        if (group.groupid === groupId) {
          const checkedGroupScreens = [...group.screens];
          const checkedDeviceTypes = [...group.deviceTypes];
          if (checkedGroupScreens.length > 1) {
            var position = checkedGroupScreens.indexOf(clickedScreenId);
            checkedGroupScreens.splice(position, 1);
            checkedDeviceTypes.splice(position, 1);
            group.screens = checkedGroupScreens;
            group.deviceTypes = checkedDeviceTypes;
          } else {
            checkedboxesData.splice(index, 1);
          }
        }
      });
    }
    setCheckedGroupsData(checkedboxesData);
  };
  //Multi config form
  const multiconfigDevice = () => {
    if (checkedGroupsData.length > 15) {
      let errorMsg = t("Maximum 15 devices can be selected");
      notify(errorMsg);
      return false;
    } else {
      const selectedDeviceTypes = [];
      checkedGroupsData.forEach((element) => {
        element.deviceTypes.forEach((deviceType) => {
          selectedDeviceTypes.push(deviceType);
        });
      });
      const isAllInfotainment = selectedDeviceTypes.every(
        (element) => element === "Infotainment"
      );
      const isAlleView = selectedDeviceTypes.every(
        (element) => element === "eView2"
      );
      const isAllFullcontent = selectedDeviceTypes.every(
        (element) => element === "Fullcontent"
      ); 
    
      const searchParam = isAllInfotainment
        ? "infotainment"
        : isAlleView
          ? "eView"
        : isAllFullcontent
          ? "Fullcontent"          
          : "infotainment_eView";
      history.push({
        pathname: "MultiDeviceConfig",
        search: `device_types=${searchParam}`,
        state: checkedGroupsData,
      });
    }
  };

  // Update local storage
  var schedulePlaylistData = JSON.parse(
    localStorage.getItem("schedulePlaylist")
  );
  const checkedScreensData = [...checkedGroupsData];
  const checkedGroupScreens = [];
  checkedScreensData.forEach((group, index) => {
    group.screens.forEach((screens, index) => {
      checkedGroupScreens.push(screens);
    });
  });
  if (schedulePlaylistData) {
    if (checkedGroupScreens) {
      schedulePlaylistData.screenIds = checkedGroupScreens;
      schedulePlaylistData.screenIdsCount = checkedGroupScreens.length;
      schedulePlaylistData.ScheduleType = "forever";
      schedulePlaylistData.ScheduleTypeData = "";
      schedulePlaylistData.Days = [];
      localStorage.setItem(
        "schedulePlaylist",
        JSON.stringify(schedulePlaylistData)
      );
    }
  }

  const offlineremoved = (nid, e) => {
    let selectedNids = schedulePlaylistData.screenIds;
    var index = selectedNids.indexOf(nid);
    selectedNids.splice(index, 1);
    if (selectedNids.length == 0) {
      setmodalShow(false);
    }
  };

  let multidevicesettings = t("Change selected display settings");

  return (
    <>
      <div className="row">
        <h2 className="col-md-3 sectionTitle">
          <T _str="Screens" />
        </h2>
        <div className="col-md-9 sectionButtons">
          <div className="screensButtons">
            <button
              onClick={multiconfigDevice}
              id="multiple-device-setting"
              title={multidevicesettings}
              disabled={
                schedulePlaylistData && schedulePlaylistData.screenIdsCount > 1
                  ? 0
                  : 1
              }
              className="btn btn-success"
            >
              {multidevicesettings}
            </button>
            <button
              onClick={modalHandleShow}
              id="add-playlist-action"
              disabled={
                schedulePlaylistData && schedulePlaylistData.screenIdsCount > 0
                  ? 0
                  : 1
              }
              className="btn btn-success"
            >
              <FaPlusCircle /> <T _str="Add Playlist" />
            </button>
          </div>
        </div>
      </div>
      <div className="row screens">
        {props.loading === false &&
          props.schedulesLoading === false &&
          props.screendata.length > 0 ? (
          <Accordion defaultActiveKey="0">
            {props.screendata.map((group, index) => {
              const groupId = group.groupid.toString();

              const checkedScreensData = [...checkedGroupsData];
              const checkedGroupScreens = [];
              checkedScreensData.forEach((group, index) => {
                group.screens.forEach((screens, index) => {
                  checkedGroupScreens.push(screens);
                });
              });

              return (
                <Accordion.Item eventKey={group.id} key={group.id}>
                  <Accordion.Header>{group.grouptitle}</Accordion.Header>
                  <input
                    type="checkbox"
                    id={group.groupid}
                    name="groupselect"
                    className={style.groupselect}
                    checked={checkedGroups.includes(groupId) ? 1 : 0}
                    onChange={(event) => [
                      handleCheckedGroups(groupId, event),
                      props.screenClicked(checkedGroupScreens, event),
                    ]}
                  />
                  <Accordion.Body>
                    {Object.values(group.arrangescreens).map(
                      (screennode, groupindex) => {
                        const screenclass = `${Object.values(screennode).length > 1
                            ? "screengroup"
                            : ""
                          }`;
                        return (
                          <div
                            className={style.unitDetails + " " + screenclass}
                          >
                            {Object.values(screennode).map((screen, oindex) => {
                              localStorage.setItem("DashboxCreated", "false");
                              const screenId = screen.screen_id.toString();
                              const themeData = screen.device_theme_blob;
                              const themeURL = themeData.replace(/&amp;/g, "&");
                              return (
                                <div className="inner">
                                  <div className={style.unitimage}>
                                    {themeURL ? (
                                      <img src={themeURL} alt="Device Theme" />
                                    ) : (
                                      <img src={noimage} alt="No Theme" />
                                    )}
                                  </div>
                                  <div className={style.unitselect}>
                                    {checkedGroupsData.map((checks, index) => {
                                      if (group.groupid == checks.groupid) {
                                        var selectedGroupsScreens =
                                          checks.screens;
                                        localStorage.setItem(
                                          "DashboxCreated",
                                          "true"
                                        );
                                        return (
                                          <input
                                            type="checkbox"
                                            id={screen.screen_id}
                                            checked={
                                              selectedGroupsScreens.includes(
                                                screenId
                                              )
                                                ? 1
                                                : 0
                                            }
                                            onChange={(event) => {
                                              handleCheckedScreens(
                                                screen.screen_id,
                                                screen.device_type,
                                                group.groupid,
                                                event
                                              );
                                              props.screenClicked(
                                                checkedGroupScreens,
                                                event
                                              );
                                            }}
                                          />
                                        );
                                      }
                                    })}
                                    {localStorage.getItem("DashboxCreated") ===
                                      "false" && (
                                        <input
                                          type="checkbox"
                                          id={screen.screen_id}
                                          onChange={(event) => {
                                            handleCheckedScreens(
                                              screen.screen_id,
                                              screen.device_type,
                                              group.groupid,
                                              event
                                            );
                                            props.screenClicked(
                                              checkedGroupScreens,
                                              event
                                            );
                                          }}
                                        />
                                      )}
                                  </div>
                                  <div className={style.unittitle}>
                                    {screen.screen_title}
                                  </div>
                                  <div className={style.cpid}>
                                    CP-ID: {screen.cpid}
                                  </div>
                                  <div className={style.deviceType}>
                                    <T _str="Device Type" />:{" "}
                                    {screen.device_type}
                                  </div>
                                  <ScreensAccordionActions
                                    screenId={screen.screen_id}
                                    schedulesData={props.schedulesData}
                                    playlistsData={props.playlistsData}
                                    othersPlaylistsData={
                                      props.othersPlaylistsData
                                    }
                                    cpid={screen.cpid}
                                    infomsg={screen.infotainment_message}
                                    themedownload={screen.theme_download}
                                    devicetheme={screen.device_theme_blob_full.replace(
                                      /&amp;/g,
                                      "&"
                                    )}
                                    countryDefaultPlaylist={
                                      props.countryDefaultPlaylist
                                    }
                                    screenStatusData={props.screenStatusData}
                                    bannerbgcolor={
                                      screen.bgColor !== "" ? screen.bgColor : '#ffffff'
                                    }
                                    bannertextcolor={
                                      screen.textColor !== "" ? screen.textColor : '#06599b'
                                    }
                                  />
                                </div>
                              );
                            })}
                          </div>
                        );
                      }
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        ) : (
          <>
            {props.loading || props.schedulesLoading ? (
              <p>
                <br />
                <T _str="Loading" />
              </p>
            ) : (
              <div className="col-md-12">
                <T _str="No Screens Found" />
              </div>
            )}
          </>
        )}
      </div>
      <Modal
        show={modalShow}
        onHide={modalHandleClose}
        className="topModal addPlaylilstModal"
      >
        <AddPlaylistsModal
          screensData={props.screendata}
          offlineremoved={offlineremoved}
          closeModalpop={modalHandleClose}
        />
      </Modal>
    </>
  );
}

export default ScreensAccordion;
