import React, { useEffect } from "react";
import { t, tx } from "@transifex/native";
import { NavLink } from "react-router-dom";
import config from "../../utils/config";
import axios from "axios";
import { useHistory } from "react-router-dom";
import DevicesCount from "./DevicesCount";
import { ToastContainer, toast } from "react-toastify";
import { dateformat } from '../../utils/dateFormat';

let userDetails = localStorage.getItem("userdetails");
let loggedInUserID = "";
if (userDetails !== null) {
  loggedInUserID = JSON.parse(userDetails).id;
}

const notify = (val) => {
  toast.error(val, {
    toastId: loggedInUserID,
  });
};

const UserTable = ({
  activeUsersTab,
  users,
  onAllUsersSelection,
  onUserSelection,
  isAllSelected,
  selectedUsers,
  showStatus = false,
  noDataMsg,
}) => {
  useEffect(() => {
    if (localStorage.getItem("imp_msg")) {
      setTimeout(() => {
        const locale = localStorage.getItem("userlang");
        tx.setCurrentLocale(locale).then(() => {
          notify(t(localStorage.getItem("imp_msg")));
          localStorage.setItem("imp_msg", "");
        });
      }, "3000");
    }
  }, []);

  const history = useHistory();
  const handleImpersonation = (data) => {
    localStorage.setItem("imp_user", data.uid);
    localStorage.setItem(
      "imp_username",
      data.field_firstname + " " + data.field_lastname
    );
    localStorage.setItem("parentuser", localStorage.getItem("userdetails"));
  };
  return (
    <div className="tbody-div">
      <table className="views-table cols-7 table table-hover table-striped">
        <thead>
          <tr>
            <th className="views-field views-row-selector" scope="col">
              <div className="form-item form-type-checkbox checkbox">
                {" "}
                <label className="control-label">
                  <input
                    className="views-row-selector-table-select-all form-checkbox"
                    type="checkbox"
                    value="1"
                    checked={isAllSelected}
                    onChange={onAllUsersSelection}
                  />
                </label>
              </div>
            </th>
            <th className="views-field field-eserviceunitid" scope="col">
              {t("Email")}{" "}
            </th>
            <th className="views-field field-mpdids" scope="col">
              {t("First Name")}
            </th>
            <th className="views-field field-unit-streetaddress" scope="col">
              {" "}
              {t("Last Name")}
            </th>
            <th className="views-field field-unit-city" scope="col">
              {" "}
              {t("Company")}
            </th>
            <th className="views-field field-unit-city" scope="col">
              {" "}
              {t("Language")}
            </th>
            {activeUsersTab && (
              <th className="views-field field-unit-city" scope="col">
                {" "}
                {t("Associated Devices")}
              </th>
            )}
            {showStatus && (
              <th className="views-field field_disable_status" scope="col">
                {t("Status")}
              </th>
            )}
            <th className="views-field field-unit-city" scope="col">
              {" "}
              {t("Created Date")}
            </th>
            <th className="views-field edit-node" scope="col">
              {" "}
              {t("Actions")}
            </th>
          </tr>
        </thead>

        {users.length === 0 ? (
          <tr className="user-default-text">
            <td colSpan={8}> {noDataMsg} </td>
          </tr>
        ) : (
          users.map((row, index) => {
            var createdOn = dateformat(row.created, 'users');
            return (
              <tbody>
                <tr className="views-row box" key={index}>
                  <td className="views-field views-row-selector">
                    <div className="form-item form-item-views-row-selector-0 form-type-checkbox checkbox">
                      <label
                        className="control-label"
                        for="edit-views-row-selector-0"
                      >
                        <input
                          className="views-row-selector-select vbo-select form-checkbox"
                          type="checkbox"
                          id={`edit-views-bulk-operations-${index}`}
                          name={`views_bulk_operations[${index}]`}
                          value={row.uid}
                          checked={selectedUsers.includes(row.uid)}
                          onChange={
                            showStatus
                              ? (e) => onUserSelection(e, row)
                              : onUserSelection
                          }
                        />
                      </label>
                    </div>
                  </td>
                  <td className="views-field field-eserviceunitid">
                    {row.mail}
                  </td>
                  <td className="views-field "> {row.field_firstname}</td>
                  <td className="views-field field-unit-streetaddress">
                    {row.field_lastname}
                  </td>
                  <td className="views-field field-unit-city">
                    {row.field_companyname}
                  </td>
                  <td className="views-field field-assign-user">
                    {row.preferred_langcode}
                  </td>
                  {activeUsersTab && <DevicesCount uid={row.uid} />}
                  {showStatus && (
                    <td className="views-field field_disable_status">
                      {t(row.field_disable_status)}
                    </td>
                  )}
                  <td className="views-field field-assign-user">
                    {createdOn}
                  </td>
                  <td className="views-field edit-node">
                    {" "}
                    <NavLink
                      activeClassName="active"
                      to={`/profile/${row.uid}`}
                    >
                      {t("View")}
                    </NavLink>
                    {activeUsersTab && !localStorage.getItem("parentuser") ? (
                      <a href="/masq" onClick={() => handleImpersonation(row)}>
                        {" "}
                        | {t("Access account")}
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              </tbody>
            );
          })
        )}
      </table>
    </div>
  );
};

export default UserTable;
