var CryptoJS = require("crypto-js");
export default {
  drupal_url: process.env.REACT_APP_drupal_url,
  rest_node_url: process.env.REACT_APP_rest_node_url,
  AzureMapFixlatitude: process.env.REACT_APP_AzureMapFixlatitude,
  AzureMapFixlongitude: process.env.REACT_APP_AzureMapFixlongitude,
  AzureMapKey: process.env.REACT_APP_AzureMapKey,
  news_url: process.env.REACT_APP_news_url,
  japan_url: process.env.REACT_APP_japan_url,
  bannertext_linescount: process.env.REACT_APP_bannertext_linescount,
  global_default_hash: process.env.REACT_APP_global_default_hash
}
