/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: Deletelive.js
File Description: Implements delete livecontent functionality of CMS portal

Copyright 2022 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { T } from '@transifex/react';
import { t } from "@transifex/native"
import config from '../utils/config';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from "react-loading-overlay";

const Deletelive = (props) => {
   
  const [loading, setLoading] = useState(false);
    
	const live_id = props.livenid.nid
	const live_title = props.livenid.title

   const onRemovelive = (liveid) =>{
        const livevalue={
          "id": liveid
        }
       setLoading(true);
       axios.post(config.drupal_url +'/livecontentdelete' ,livevalue,{'withCredentials': true })
         .then((reponse) => {
           if(reponse.data.message == true){
            var str = t("@type %title was successfully deleted.");
            var mapObj = { "@type": t("Live content"), "%title": reponse.data.title };
            str = str.replace(/@type|%title/gi, function (matched) {
                return mapObj[matched];
            });
            setLoading(false);
            toast.success(str);
            } else {
              toast.error(t('Live Content is curretly used in playlist(s) and cannot be deleted.'));
           }
          setTimeout(() => {
            setLoading(true);
            window.location.assign('/live-content-management');
          }, 5000);

          })
        .catch(function (error) {
          console.error('There was an error!', error);
     })
     return null;
  }

const delmessage = t('Are you sure you want to delete %title?').replace('%title',live_title) ;
return (
  <LoadingOverlay active={loading} spinner text={t('Processing')}>
    <div>
      <div className="groups-popup-container dlt-popup">
            <div className="popup-title">

                <Modal.Header closeButton>
                <T _str="Delete" /> <T _str="Live Content" />
                </Modal.Header>
            </div>
            <div className="popup-content">
                <div>{delmessage}</div>
                <div><T _str="This action cannot be undone." /></div>
                <div class="form-actions form-wrapper form-group" id="edit-actions">

                 </div>

            </div>

        </div>
        <div className='modal-footer'>
            <button type="submit" id="edit-submit"
                name="op" value="Delete"
                onClick={(event)=>[props.closemodal(event), onRemovelive(live_id)]}
                class="btn btn-danger form-submit icon-before">
                <span class="icon glyphicon glyphicon-trash" aria-hidden="true"></span>
                <T _str="Delete"/></button>
                <button type="submit" id="edit-cancel" name="op" value="Cancel"  onClick={(event)=>props.closemodal(event)} class="btn btn-danger form-submit icon-before ms-1 btn-cancel"><T _str="Cancel"/></button>
            </div>
    </div>
    </LoadingOverlay>
 );
}

export default Deletelive;