/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: Dashboard.js
File Description: Implements dashboard functionality of CMS portal

Copyright 2021,2022 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import ScreensAccordion from "../Components/Screens/ScreensAccordion";
import DashboardPlaylists from "../Components/PlaylistBlock/DashboardPlaylists";
import { useState, useEffect } from "react";
import axios from "axios";
import config from "../utils/config";
import "../css/screens.css";
import { T, useT } from "@transifex/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "react-loading-overlay";
import { t } from "@transifex/native";

function Screens() {
  const tabTitle = t("Screens");
  const [loading, setLoading] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [schedulesLoading, setSchedulesLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [playlistsData, setPlaylistsData] = useState([]);
  const [schedulesData, setschedulesData] = useState([]);
  const [screenStatusData, setscreenStatusData] = useState([]);
  const [GroupsCount, setGroupsCount] = useState(false);
  const [screensCount, setscreensCount] = useState(false);
  const [PlaylistsCount, setPlaylistsCount] = useState(false);
  const [refresh, SetRefresh] = useState(false);
  const [schedulesLoaded, setSchedulesLoaded] = useState('');
  const [playlistsLoaded, setPlaylistsLoaded] = useState('');
  const [screensLoaded, setScreensLoaded] = useState('');
  const [screenschedulesLoaded, setScreenschedulesLoaded] = useState('');
  const [othersPlaylistsData, setOthersPlaylistsData] = useState([]);
  const [countryDefaultPlaylist, setCountryDefaultPlaylist] = useState([]);
  const [Page, setPage] = useState(0);
  const [PagePlaylist, setPagePlaylist] = useState(0);
  const [userList, setUserList] = useState([]);
  const [totalItems, settotalItems] = useState([]);
  const [totalItemsPlaylist, settotalItemsPlaylist] = useState([]);
  const [flag, setFlag] = useState(0);

  var token = localStorage.getItem("userdetails");
  var uid = token && JSON.parse(token).id;
  const perPage = 10;
  const perPagePlaylist = 7;
  // Browser Tab Title
  useEffect(() => {
    document.title = tabTitle + " | CMS";
    localStorage.removeItem("offlineUnits");
  }, [tabTitle]);

  useEffect(() => {
    

    const fetchScreens = async () => {
      SetRefresh(true);
      setLoading(true);
      await axios
        .get(config.drupal_url + "/api/views/groups-screens-data/" + uid + "?items_per_page="+perPage+"&page="+Page)
        .then((response) => {
          const devicesData = response.data.rows;
		  settotalItems(response.data.pager.total_items);
          const screennewDataFiltered = [];
          devicesData.forEach((group, index) => {
            if (group.screens.length !== 0) {
              screennewDataFiltered.push(group);
            }
          });
          const newTodos = [...userList,...screennewDataFiltered];
    	  setUserList(newTodos);
          var screensCount = 0;
          const screenDataFiltered = [];
          newTodos.forEach((group, index) => {
            if (group.screens.length !== 0) {
              screenDataFiltered.push(group);
            }
          });
		    
          setDashboardData(screenDataFiltered);
          setScreensLoaded('yes');
		  setScreenschedulesLoaded('yes');
          setLoading(false);
          setGroupsCount(response.data.pager.total_items);
       //   setscreensCount(screensCount);
          //var token = localStorage.getItem("refresh_once").refresh
          //var refresh = token && JSON.parse(token)
          // var refresh = window.localStorage.getItem('refresh');
       //   if (refresh) {
       //     SetRefresh(false);
       //     window.location.reload();
            //localStorage.setItem('userlang', JSON.stringify(res.data.user_lang));
            //window.localStorage.setItem('refresh_once', "two");
       //   }
        })
        .catch(function (error) {});
    };
    fetchScreens();
    
  }, [Page]);
  
  useEffect(() => {
	if(dashboardData.length > 0 && screenschedulesLoaded == 'yes') {
        var screenIds = [];
        dashboardData.forEach((group, index) => {
          group.screens.forEach((screen, index) => {
            screenIds.push(screen.screen_id);
          });
        });
	  let uniquescreenids = [...new Set(screenIds)];
	  var schedulescreenids=uniquescreenids.join("+");
	    
      const fetchSchedules = async () => {
      setSchedulesLoading(true);
      await axios
        .get(
          config.drupal_url +
            "/api/views/schedules/"+schedulescreenids+"?timestamp=" +
            new Date().toJSON()
        )
        .then((response) => {
          setschedulesData(response.data);
          setSchedulesLoaded('yes');
		  setScreenschedulesLoaded('no'); 
		  setSchedulesLoading(false);
        });
    };
    fetchSchedules();
	
	var screenids=uniquescreenids.join("||");
	 const getStatus = async () => {
     await axios
        .get(config.drupal_url + `/api/devicestatus?ids=`+screenids, {
        withCredentials: true,
      })
      .then((response) => {
		setscreenStatusData(response.data);         
        response.data.forEach((screenstatus, index) => {
        const status = screenstatus.field_device_status_value;
        if (status === "Disconnected") {
          var offline = JSON.parse(localStorage.getItem("offlineUnits") || '[]');
         if (!offline.includes(screenstatus.entity_id)) {
            offline.push(screenstatus.entity_id);
            localStorage.setItem("offlineUnits", JSON.stringify(offline));
          }
        }
      });
      })
  };

   getStatus();
    }
  }, [screenschedulesLoaded]);
   
    useEffect(() => {
	const fetchDevicescount = async () => {
        await axios
        .get(
          config.drupal_url +
            "/api/getdevicecount?uid=" + uid, {
        withCredentials: true,
        }
         )
        .then((response) => {
          setscreensCount(response.data.device_count);
       });
    };
	  fetchDevicescount();

  const schedulePlaylistData = {
      PlaylistId: "",
      PlaylistTitle: "",
      PlaylistType: "",
      screenIds: [],
      source: "",
    };
    localStorage.removeItem("schedulePlaylist");
    localStorage.setItem(
      "schedulePlaylist",
      JSON.stringify(schedulePlaylistData)
    );
  
   const fetchPlaylists = async () => {
      setIsLoading(true);
      await axios
        .get(config.drupal_url + "/api/views/dashboard-playlists-block/" + uid + "?items_per_page="+perPagePlaylist+"&page="+PagePlaylist)
        .then((response) => {
	  let newdata;
      if (flag === 1) {
        newdata = [...playlistsData, ...response.data.rows];
	  } else {
	    newdata = response.data.rows;
	  }
	    setPlaylistsData(newdata);
        setPlaylistsLoaded('yes');
        setIsLoading(false);
		settotalItemsPlaylist(parseInt(response.data.pager.total_items));
        setPlaylistsCount(response.data.pager.total_items);
      });
    };
    fetchPlaylists();
    
    var userdata = localStorage.getItem("userdetails");
    var uCountryid = userdata && JSON.parse(userdata).cid;
    // Fetches country default playlist
    const fetchDefaultPlaylists = async () => {
      await axios
        .get(config.drupal_url + "/api/views/country-default-playlist/" + uCountryid)
        .then((response) => {
          setCountryDefaultPlaylist(response.data);
        });
    };
    fetchDefaultPlaylists();
	
	 }, [PagePlaylist]);

  // When a unit is assigned to current user where other user's playlist is playing
  // pull those currently playing playlist's data for preview 
  useEffect(() => {
    // waits till other api calls are completed
    if((schedulesLoaded == 'yes') && (playlistsLoaded == 'yes') && (screensLoaded == 'yes')) {
      if(dashboardData.length > 0) {
        var screenIds = [];
        dashboardData.forEach((group, index) => {
          group.screens.forEach((screen, index) => {
            screenIds.push(screen.screen_id);
          });
        });
        var othersPlaylistIds = '';
        schedulesData.forEach((schedule, index) => {
          if (screenIds.includes(schedule.screen_id)) {
            if ((schedule.transmit === 'SUCCESS') || (schedule.transmit === 'success') || (schedule.transmit === 'Success')) {
              if(othersPlaylistIds) {
                othersPlaylistIds = othersPlaylistIds + "+" + schedule.playlist_id;
              } else {
                othersPlaylistIds = schedule.playlist_id;
              }
            }
          }
        });
 		 if (othersPlaylistIds) {
        const fetchOthersPlaylists = async () => {
          await axios
            .get(config.drupal_url + "/api/views/dashboard-others-playlists-block/" + othersPlaylistIds)
            .then((response) => {
              setOthersPlaylistsData(response.data);
            });
        };
        fetchOthersPlaylists();
		}
      }
    }
  }, [schedulesLoaded, playlistsLoaded, screensLoaded]);

  function screenClicked(ids, event) {
    // setSelectedScreens(ids.toString());
    //setAddPlaylistButton(ids.length > 0 ? true : false);
    //setChangeSettingsButton(ids.length > 1 ? true : false);
  }

  return (
    <div>
      <ToastContainer />
      {uid ? (
        <LoadingOverlay active={loading} spinner text={t("Processing")}>
          <div className="headerSection col-12 d-inline-block">
            <h1 className="pageTitle col-12">
              eView<sup>&reg;</sup> - <T _str="Dashboard" />
            </h1>
            <div className="subtitle col-12">
              <T _str="You have" /> {GroupsCount} <T _str="groups" />,{" "}
              {screensCount} <T _str="screens and" /> {PlaylistsCount}{" "}
              <T _str="playlists" />.
            </div>
          </div>

          <div className="row col-md-12">
            <div className="col-lg-8 col-md-12 section-dashboard-screens">
              <ScreensAccordion
                screendata={dashboardData}
                screenClicked={screenClicked}
                schedulesData={schedulesData}
                loading={loading}
                schedulesLoading={schedulesLoading}
                playlistsData={playlistsData}
                othersPlaylistsData={othersPlaylistsData}
                countryDefaultPlaylist={countryDefaultPlaylist}
				screenStatusData={screenStatusData}
			  />
          
              {(perPage * (Page + 1)) < totalItems &&   <div className="device-list-footer"> <button className="btn-load-more" onClick={() => { setPage(Page + 1); setScreensLoaded('no'); }}>{loading ? '' : <T _str='Load more' />}</button>
               <span className="list-count">{t('Displaying @start - @end of @total').replace('@start', 1).replace('@end', (perPage * (Page + 1)) < totalItems ? perPage * (Page + 1) : totalItems).replace('@total', totalItems)}</span>
			  </div> }
          </div>
            <div className="col-lg-4 col-md-12 section-dashboard-playlists">
              <h2 className="sectionTitle">
                <T _str="Playlists" />
              </h2>
			
			               <DashboardPlaylists
                playlistsData={playlistsData}
                isloading={isloading}
              />

	 {(perPagePlaylist * (PagePlaylist + 1)) < totalItemsPlaylist && !isloading &&  <div className="playlist-footer"> <button className="btn-load-more-playlist" onClick={() => { setIsLoading(true); setPagePlaylist(PagePlaylist + 1);  setFlag(1);   }}><T _str='Load more' /></button>
               <span className="list-count">{t('@start - @end of @total').replace('@start', 1).replace('@end', (perPagePlaylist * (PagePlaylist + 1)) < totalItemsPlaylist ? perPagePlaylist * (PagePlaylist + 1) : totalItemsPlaylist).replace('@total', totalItemsPlaylist)}</span>
			  </div> }
			  
			    {(perPagePlaylist * (PagePlaylist + 1)) < totalItemsPlaylist && isloading &&  <div className="playlist-footer"><span class="loading-text"><T _str='Loading' />...</span>
      		  </div> }
		
            
            </div>
          </div>
        </LoadingOverlay>
      ) : (
        <LoadingOverlay
          active={true}
          spinner
          text={t("Processing")}
        ></LoadingOverlay>
      )}
    </div>
  );
}

export default Screens;
