/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: Masq.js
File Description: Implements switch user functionality

Copyright 2022 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import { useState, useEffect } from 'react';
import axios from "axios";
import config from '../utils/config';
import { T, useT } from '@transifex/react';
import LoadingOverlay from "react-loading-overlay";
import { useParams, useHistory } from "react-router-dom";
import { t } from "@transifex/native";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Masq(props) {

  const tabTitle = useT("Manage Users");
  const history = useHistory();
  let imp_user = localStorage.getItem("imp_user")
  var token = localStorage.getItem("userdetails")
  var uid = token && JSON.parse(token).id
  // Get logged in user details
  let userDetails = localStorage.getItem("userdetails");
  let loggedInUserID = '';
  if (userDetails !== null) {
    loggedInUserID = JSON.parse(userDetails).id;
  }

  useEffect(() => {
    document.title = tabTitle + ' | CMS';
    handleImpersonation();
  }, []);

  //const [mediatype, setMediaType] = useState("");
  const handleImpersonation = async () => {
    if (imp_user > 0) {
      await axios.get(config.drupal_url + '/userdetails?&uid=' + imp_user, { withCredentials: true })
        .then((response) => {
          if(response.data.message){
          localStorage.setItem('imp_user',null)
          localStorage.setItem('imp_username','')
          localStorage.setItem('parentuser','')
          localStorage.setItem('imp_msg',response.data.message)
          //toast.error(t(response.data.message));
          //document.getElementById('admin-sticky-message').style.display = "none";
          //setTimeout(
          //  function() {
              //window.location.reload(false);
          //  },
          //  1000
          //);
          history.push('/manage-users');
          }else{
            localStorage.setItem('userdetails', JSON.stringify(response.data))
            localStorage.setItem("imp_user", null)
            localStorage.setItem("userlang", response.data.language)
            let rurl = config.drupal_url + '/masq'
            window.location.replace(rurl)
           }
        });

    }

    if (localStorage.getItem("unmask") > 0 && imp_user == 'null') {
      axios.get(config.drupal_url + '/userdetails?unmask=1&uid=' + uid, { withCredentials: true })
        .then((response) => {
          localStorage.setItem('userdetails', JSON.stringify(response.data))
          localStorage.setItem('parentuser', '')
          localStorage.setItem("userlang", response.data.language)
          window.location.reload(false);
          let rurl = config.drupal_url + '/masq?unmask=1'
          window.location.replace(rurl)

        });

      localStorage.setItem("unmask", 0)
    }
  }

  return (
    <LoadingOverlay active={true} spinner text={t('Processing')}>
    </LoadingOverlay>
  );
}

export default Masq;
