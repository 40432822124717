/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: EditLiveContent.js
File Description: Implements edit livecontent functionality of CMS portal

Copyright 2022,2023 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import React, { useState, useEffect } from 'react';
import { T } from '@transifex/react';
import { t } from "@transifex/native"
import "../css/livecontent.css"
import config from '../utils/config';
import { Modal } from 'react-bootstrap';
import $ from "jquery";
import LoadingOverlay from "react-loading-overlay";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import Deletelive from './Deletelive';

function EditLiveContent(props) {
  window.long = config.AzureMapFixlongitude;
  window.lati = config.AzureMapFixlatitude;
  window.subscriptionkey = config.AzureMapKey;

  const [languagesData, setLanguagesData] = useState([]);
  const [modalShow, setmodalShow] = useState(false);
  const [modalData, setModalData] = useState([]);
  const modalHandleClose = () => setmodalShow(false);

  const nid = props.id;
  const [formData, setFormData] = useState({
    title: "",
    feed_type: "",
    feed_url: "",
    preview_image: "",
    language: "",
    metric: "",
    lat: "",
    lon: "",
    users: ""
  });

  const regExpEscape = (literal_string) => {
    return literal_string.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&');
  }
  const closemodal = (event) => {
    event.preventDefault();
    setmodalShow(false);
  }
  // Browser Tab Title
  useEffect(() => {
    setLoading(true);
    const getLiveContentFeed = async () => {
      axios.get(config.drupal_url + '/api/livecontent_detail/' + nid + '?_format=json', { withCredentials: true })
        .then(response => {
          let tabTitle = t("Edit @type @title");
          var mapObj = { "@type": t("Live Content"), "@title": response.data[0].title };
          tabTitle = tabTitle.replace(/@type|@title/gi, function (matched) {
            return mapObj[matched];
          });
          document.title = tabTitle + ' | CMS';
          const feedsResData = response.data;
          setFormData({
            ...formData, title: feedsResData[0].title,
            feed_url: feedsResData[0].field_feed_url,
            feed_type: feedsResData[0].field_feed_type,
            language: feedsResData[0].field_language,
            metric: feedsResData[0].field_metric,
            lat: feedsResData[0].field_location_lat,
            lon: feedsResData[0].field_location_long,
            preview_image: feedsResData[0].field_mediaimage.replace(/&amp;/g, '&'),
            users: feedsResData[0].field_assign_user
          })
          axios.get(config.drupal_url + '/Userslist/' + countryId, { withCredentials: true })
            .then(userResponse => {
              let userArr = feedsResData[0].field_assign_user.split(', ');
              var userOptions = '';
              $.each(userResponse.data, function (key, value) {
                if ($.inArray(value.uid, userArr) > -1) {
                  userOptions = '<option value="' + value.uid + '" selected>' + value.mail + '</option>'
                } else {
                  userOptions = '<option value="' + value.uid + '">' + value.mail + '</option>'
                }
                $('#edit-field-assign-user-und').append(userOptions);
              });
            })
            .then(userRespone => {
              const assignwidget = $("#improvedselect-edit-field-assign-user-und").length;
              if (assignwidget === 0) {
                $('#edit-field-assign-user-und').parent().append('<div class="improvedselect" sid="' + $('#edit-field-assign-user-und').attr('id') + '" id="improvedselect-' + $('#edit-field-assign-user-und').attr('id') + '"><div class="improvedselect-text-wrapper"><input type="text" class="improvedselect_filter" sid="' + $('#edit-field-assign-user-und').attr('id') + '" prev="" /></div><ul class="improvedselect_sel"></ul><ul class="improvedselect_all"></ul><div class="improvedselect_control"><span class="add" sid="' + $('#edit-field-assign-user-und').attr('id') + '">&gt;</span><span class="del" sid="' + $('#edit-field-assign-user-und').attr('id') + '">&lt;</span><span class="add_all" sid="' + $('#edit-field-assign-user-und').attr('id') + '">&raquo;</span><span class="del_all" sid="' + $('#edit-field-assign-user-und').attr('id') + '">&laquo;</span></div><div class="clear" /></div>');
                var improvedselect_id = $('#edit-field-assign-user-und').attr('id');
                $('#edit-field-assign-user-und').find('option').each(function () {
                  if ($(this).attr('selected')) {
                    $('#improvedselect-' + improvedselect_id + ' .improvedselect_sel').append('<li so="' + $(this).attr('value') + '">' + $(this).text() + '</li>');
                  }
                  else {
                    $('#improvedselect-' + improvedselect_id + ' .improvedselect_all').append('<li so="' + $(this).attr('value') + '">' + $(this).text() + '</li>');
                  }
                });
                $('#improvedselect-' + improvedselect_id + ' li').click(function () {
                  $(this).toggleClass('selected');
                });
                setLoading(false);
                $('#edit-field-assign-user-und').hide();
                $('.improvedselect_filter').keyup(function () {
                  var text = $(this).val();
                  if (text.length && text !== $(this).attr('prev')) {
                    $(this).attr('prev', text);
                    var patt = new RegExp(`${regExpEscape(text)}`, 'i');
                    $('#improvedselect-' + $(this).attr('sid') + ' .improvedselect_all li').each(function () {
                      var str = $(this).text();
                      if (str.match(patt)) {
                        $(this).show();
                      }
                      else {
                        $(this).hide();
                      }
                    });
                  }
                  else {
                    $(this).attr('prev', '')
                    $('#improvedselect-' + $(this).attr('sid') + ' .improvedselect_all li').each(function () {
                      $(this).show();
                    });
                  }
                });

                // Add selected items.
                $('.improvedselect .add').click(function () {
                  var sid = $(this).attr('sid');
                  $('#improvedselect-' + sid + ' .improvedselect_all .selected').each(function () {
                    $(this).removeClass('selected');
                    $(this).show();
                    $('#improvedselect-' + sid + ' .improvedselect_sel').append($(this));
                  });
                  improvedselectUpdate(sid);
                });

                // Remove selected items.
                $('.improvedselect .del').click(function () {
                  var sid = $(this).attr('sid');
                  $('#improvedselect-' + sid + ' .improvedselect_sel .selected').each(function () {
                    $(this).removeClass('selected');
                    $('#improvedselect-' + sid + ' .improvedselect_all').append($(this));
                  });
                  improvedselectUpdate(sid);
                });

                // Remove all filtered items.
                $('.improvedselect .add_all').click(function () {
                  var sid = $(this).attr('sid');
                  $('#improvedselect-' + sid + ' .improvedselect_all li').each(function () {
                    if ($(this).css('display') !== 'none') {
                      $(this).removeClass('selected');
                      $('#improvedselect-' + sid + ' .improvedselect_sel').append($(this));
                    }
                  });
                  improvedselectUpdate(sid);
                });

                // Remove all items.
                $('.improvedselect .del_all').click(function () {
                  var sid = $(this).attr('sid');
                  $('#improvedselect-' + sid + ' input').val('');
                  $('#improvedselect-' + sid + ' input').attr('prev', '');
                  $('#improvedselect-' + sid + ' .improvedselect_sel li').each(function () {
                    $(this).removeClass('selected');
                    $('#improvedselect-' + sid + ' .improvedselect_all').append($(this));
                  });
                  $('#improvedselect-' + sid + ' .improvedselect_all li').each(function () {
                    $(this).show();
                  });
                  improvedselectUpdate(sid);
                });
              }
            })
        }).then(response => {
          showHideFields();
        })
        .catch(error => console.error('API Error:' + error));
      fetchLanguages();
    }
    getLiveContentFeed();
    setTimeout(() => {
      window.GetMap(); 
     }, 5000);
  }, []);

  function improvedselectUpdate(sid) {
    //$('#'+ sid +' option:selected').attr("selected", "");
    $('#' + sid + ' option:selected').attr("selected", false);
    $('#improvedselect-' + sid + ' .improvedselect_sel li').each(function () {
      $('#' + sid + ' [value="' + $(this).attr('so') + '"]').attr("selected", "selected");
    });
    $('#' + sid).find('option').each(function () {
      if ($(this).attr("selected")) {
        console.log($('#improvedselect-' + sid + ' .improvedselect_sel [so="' + $(this).attr('value') + '"]'));
        $('#improvedselect-' + sid + ' .improvedselect_sel').append($('#improvedselect-' + sid + ' .improvedselect_sel [so="' + $(this).attr('value') + '"]'));
      }
      else {
        $('#improvedselect-' + sid + ' .improvedselect_all').append($('#improvedselect-' + sid + ' .improvedselect_all [so="' + $(this).attr('value') + '"]'));
      }
    });
  }

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  // Get logged in user details
  let userDetails = localStorage.getItem("userdetails");
  let loggedInUserID = '';
  let countryId = '';
  let countryName = '';
  var userrole = 'building_manager';
  if (userDetails === null) {
    loggedInUserID = 1;
    countryId = 10;
    countryName = 'United States';
  } else {
    loggedInUserID = JSON.parse(userDetails).id;
    countryId = JSON.parse(userDetails).cid;
    countryName = JSON.parse(userDetails).country;
    JSON.parse(localStorage.getItem("userdetails")).roles.forEach(role => {
      if (role === "country_admin") {
        userrole = "country_admin";
      }
    });
  }

  $('#field-feed-type').on("change", function (e) {
    showHideFields();
  });

  const showHideFields = function () {
    if ($('#field-feed-type').val() === 'news') {
      // Show Feed URL and Hide weather fields
      $('.news_details').show();
      $('.weather_details').hide();
    } else if ($('#field-feed-type').val() === 'weather') {
      if (countryName === "Japan") {
        $('.news_details').show();
        $('.weather_details').hide();
      }
      else {
        // Show weather fields and hide Feed URL         
        $('.weather_details').show();
        $('.news_details').hide();
        window.map.resize();
      }
    } else if ($('#field-feed-type').val() === '') {
      $('.weather_details').hide();
      $('.news_details').hide();
    }

  }
  // Fetch Languages
  const fetchLanguages = async () => {
    await axios.get(config.drupal_url + '/languagelist', { withCredentials: true })
      .then(response => {
        setLanguagesData(response.data);
      })
      .catch(function (error) { })
  };

  const handleCancel = () => {
    history.push('/live-content-management');
  }
  // Validate form
  const handleValidation = (formData) => {
    let formValid = true;
    //let errorMsg = '';
    $("label").removeClass("error-class");
    $(".error-msg").remove();
    if (formData.title.trim() === "") {
      let errorMsg = t('!name field is required.').replace('!name', t('Title'));
      $("label.title-label").addClass("error-class");
      $("#edit-title").after('<p class="error-msg" style="color:red;">' + errorMsg + '</p>');
      formValid = false;
    }
    if ((formData.title).includes(':')) {
      let errorMsg = t('!name cannot include colon (:).').replace('!name', t('Title'));
      $("label.title-label").addClass("error-class");
      $("#edit-title").after('<p class="error-msg" style="color:red;">' + errorMsg + '</p>');
      formValid = false;
    }
    if (formData.feed_type === "") {
      let errorMsg = t('!name field is required.').replace('!name', t('Feed Type'));
      $("label.feed_type-label").addClass("error-class");
      $("#field-feed-type").after('<p class="error-msg" style="color:red;">' + errorMsg + '</p>');
      formValid = false;
    }
    if (formData.preview_image === "") {
      let errorMsg = t('!name field is required.').replace('!name', t('Preview Image'));
      $("label.mediaimage-label").addClass("error-class");
      $("#edit-field-mediaimage-upload").parent().after('<p class="error-msg" style="color:red;">' + errorMsg + '</p>');
      formValid = false;
    }
    if (formData.feed_type === "weather") {
      if (countryName != "Japan") {
        if (formData.language === "") {
          let errorMsg = t('!name field is required.').replace('!name', t('Language'));
          $("label.language-label").addClass("error-class");
          $("#edit-field-language").after('<p class="error-msg" style="color:red;">' + errorMsg + '</p>');
          formValid = false;
        }
        formData.lat = $("#edit-field-location1-lat").val().trim();
        formData.lon = $("#edit-field-location1-lon").val().trim();
        formData.feed_url='';
        if (formData.lat === "") {
          let errorMsg = t('!name field is required.').replace('!name', t('Latitude'));
          $("label.lat-label").addClass("error-class");
          $("#edit-field-location1-lat").after('<p class="error-msg" style="color:red;">' + errorMsg + '</p>');
          formValid = false;
        }
        if (formData.lat !== "" && !isLatitude(formData.lat)) {
          let errorMsg = 'Invalid value ' + t('Latitude');
          $("label.lat-label").addClass("error-class");
          $("#edit-field-location1-lat").after('<p class="error-msg" style="color:red;">' + errorMsg + '</p>');
          formValid = false;
        }

        if (formData.lon === "") {
          let errorMsg = t('!name field is required.').replace('!name', t('Longitude'));
          $("label.long-label").addClass("error-class");
          $("#edit-field-location1-lon").after('<p class="error-msg" style="color:red;">' + errorMsg + '</p>');
          formValid = false;
        }
        if (formData.lon !== "" && !isLongitude(formData.lon)) {
          let errorMsg = 'Invalid value ' + t('Longitude');
          $("label.lon-label").addClass("error-class");
          $("#edit-field-location1-lon").after('<p class="error-msg" style="color:red;">' + errorMsg + '</p>');
          formValid = false;
        }
        if (formData.metric === "") {
          let errorMsg = t('!name field is required.').replace('!name', t('Metric'));
          $("label.metric-label").addClass("error-class");
          $("#edit-field-metric").after('<p class="error-msg" style="color:red;">' + errorMsg + '</p>');
          formValid = false;
        }
      }
      else {
        if (formData.feed_url.trim() === "") {
          let errorMsg = t('!name field is required.').replace('!name', t('Feed Url'));
          $("label.feed_url-label").addClass("error-class");
          $("#edit-field-feed-url").after('<p class="error-msg" style="color:red;">' + errorMsg + '</p>');
          formValid = false;
        }
        if (formData.feed_url.trim() !== "" && (!isUrlValid(formData.feed_url.trim()) || !whiteListjapanUrl(formData.feed_url.trim()))) {
          let errorMsg = t('Please enter a valid URL.');
          $("label.feed_url-label").addClass("error-class");
          $("#edit-field-feed-url").after('<p class="error-msg" style="color:red;">' + errorMsg + '</p>');
          formValid = false;
        }

      }
    } else if (formData.feed_type === "news") {
      if (countryName != "Japan") {
        if (formData.feed_url.trim() === "") {
          let errorMsg = t('!name field is required.').replace('!name', t('Feed Url'));
          $("label.feed_url-label").addClass("error-class");
          $("#edit-field-feed-url").after('<p class="error-msg" style="color:red;">' + errorMsg + '</p>');
          formValid = false;
        }
        if (formData.feed_url.trim() !== "" && (!isUrlValid(formData.feed_url.trim()) || !whiteListUrl(formData.feed_url.trim()))) {
          let errorMsg = t('Please enter a valid URL.');
          $("label.feed_url-label").addClass("error-class");
          $("#edit-field-feed-url").after('<p class="error-msg" style="color:red;">' + errorMsg + '</p>');
          formValid = false;
        }
      }
      else {
        if (formData.feed_url.trim() === "") {
          let errorMsg = t('!name field is required.').replace('!name', t('Feed Url'));
          $("label.feed_url-label").addClass("error-class");
          $("#edit-field-feed-url").after('<p class="error-msg" style="color:red;">' + errorMsg + '</p>');
          formValid = false;
        }
        if (formData.feed_url.trim() !== "" && (!isUrlValid(formData.feed_url.trim()) || !whiteListjapanUrl(formData.feed_url.trim()))) {
          let errorMsg = t('Please enter a valid URL.');
          $("label.feed_url-label").addClass("error-class");
          $("#edit-field-feed-url").after('<p class="error-msg" style="color:red;">' + errorMsg + '</p>');
          formValid = false;
        }

      }
    }
    if (userrole === 'country_admin') {
      let selectedOptions = $('#edit-field-assign-user-und').val();
      formData.users = selectedOptions;
      if (formData.users.length === 0) {
        let errorMsg = t('!name field is required.').replace('!name', t('Assign user'));
        $("label.assign-users-label").addClass("error-class");
        $("#improvedselect-edit-field-assign-user-und").after('<p class="error-msg" style="color:red;">' + errorMsg + '</p>');
        formValid = false;
      }
    } else if (userrole === 'building_manager') {
      formData.users = [loggedInUserID];
    }
    return formValid;
  }
  function isUrlValid(userInput) {
    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if (res == null)
      return false;
    else
      return true;
  }

  function whiteListUrl(userInput) {
    var allowed = (config.news_url).split(',');
    var urlObject = new URL(userInput);
    return allowed.indexOf(urlObject.host) > -1;
  }
  function whiteListjapanUrl(userInput) {
    var allowed = (config.japan_url).split(',');
    var urlObject = new URL(userInput);
    return allowed.indexOf(urlObject.host) > -1;
  }

  function isLatitude(lat) {
    return isFinite(lat) && Math.abs(lat) <= 90;
  }

  function isLongitude(lng) {
    return isFinite(lng) && Math.abs(lng) <= 180;
  }

  const handleFeedSubmit = (e) => {
    let validForm = handleValidation(formData);
    formData.node_id = nid;
    if (validForm) {
      setLoading(true);
      //Trim values before saving
      formData.title = formData.title.trim();
      formData.feed_url = formData.feed_url.trim();
      formData.lat = formData.lat.trim();
      formData.lon = formData.lon.trim();

      // Update url
      axios.post(config.drupal_url + '/updatelivecontent?_format=json', JSON.stringify(formData),
        { withCredentials: true })
        .then((result) => {
          if (result.data.success && result.data.success === 'LiveContentUpdated') {
            setLoading(false);
            var str = t("@type %title has been updated.");
            var mapObj = { "@type": t("Live Content"), "%title": formData.title };
            str = str.replace(/@type|%title/gi, function (matched) {
              return mapObj[matched];
            });
            toast.success(str);
            setTimeout(() => {
              setLoading(true);
              window.location.assign('/live-content-management');
            }, 5000);
          } else if (result.data.error && result.data.error === 'AlreadyExists') {
            setLoading(false);
            toast.error(t('Feed with same name already exists. Please use a different name.'));
            return false;
          } else if (result.data.error && result.data.error === 'InvalidFeedUrl') {
            setLoading(false);
            toast.error(t('Please enter a valid URL.'));
            return false;
          }
        })
        .catch(function (error) {
          setLoading(false);
          toast.error(error.response);
        })
    }
  }

  return (
    <LoadingOverlay active={loading} spinner text={t('Processing')}>
      <ToastContainer />
      <div className="create-live-content">
        <div className="page-heading">
          <h2>
            eView<sup>&reg;</sup> -
            <T _str="Edit live content" />
          </h2>
        </div>
        <div className="live-content-inner">
          <a href="/live-content-management" className="go-to">
            <T _str="Go to Manage Live Content" />
          </a>
          <div className="form-item form-item-title">
            <label className="control-label title-label" htmlFor="edit-title">
              <T _str="Title" />
              <span className="form-required" >*</span>
            </label>
            <input className="form-control form-text required" type="text"
              id="edit-title" name="title"
              value={formData.title} size="60" maxlength="25"
              onChange={(e) => setFormData({ ...formData, title: e.target.value })} />
          </div>
          <div className="form-item form-type-select">
            <label className="control-label feed-type-label" htmlFor="edit-field-feed-type">
              <T _str="Feed Type" />
              <span className="form-required" >*</span>
            </label>
            <select className="form-control form-select required"
              id="field-feed-type" name="field_feed_type" value={formData.feed_type} onChange={(e) =>
                setFormData({ ...formData, feed_type: e.target.value })}>
              <option value="">- {t('Select a value')} -</option>
              <option value="news">{t('news')}</option>
              <option value="weather">{t('weather')}</option>
            </select>
          </div>
          <div className="form-item form-type-managed-file">
            <label className="control-label mediaimage-label" htmlFor="edit-field-mediaimage-upload">
              <T _str="Preview Image" />
              <span className="form-required" >*</span>
            </label>
            <div className="image-widget form-managed-file clearfix">
              <div className="image-widget-data">
                <div className="input-group">
                  <img src={formData.preview_image} alt="Live Content Image" />
                </div>
              </div>
            </div>
          </div>
          <div className="form-item form-group news_details">
            <label className="control-label feed-url-label" htmlFor="edit-field-feed-url">
              <T _str="Feed Url" />
              <span className="form-required">*</span>
            </label>
            <div className="link-field-subrow clearfix">
              <div className="link-field-url">
                <div className="form-item">
                  <input className="form-control form-text required"
                    type="text" id="edit-field-feed-url" name="field_feed_url"
                    size="60" maxlength="2048" value={formData.feed_url}
                    onChange={(e) => setFormData({ ...formData, feed_url: e.target.value })} />
                </div>
              </div>
            </div>
          </div>
          <div className="form-item form-item-field-language form-type-select weather_details">
            <label className="control-label language-label" htmlFor="edit-field-language">
              <T _str="Language" />
              <span className="form-required" >*</span>
            </label>
            <select className="form-control form-select required" id="edit-field-language" name="field_language" value={formData.language} onChange={(e) =>
              setFormData({ ...formData, language: e.target.value })}>
              <option value="">- {t('Select a value')} -</option>
              {Object.keys(languagesData).map((keyName, i) => (
                <option value={keyName}>{t(languagesData[keyName])}</option>
              ))}
            </select>
          </div>
          <div className="form-item form-item-field-metric form-type-select weather_details">
            <label className="control-label metric-label" htmlFor="edit-field-metric">
              <T _str="Metric" />
              <span className="form-required" >*</span>
            </label>
            <select className="form-control form-select required" id="edit-field-metric" name="field_metric" value={formData.metric} onChange={(e) =>
              setFormData({ ...formData, metric: e.target.value })}>
              <option value="">- {t('Select a value')} -</option>
              <option value="true">{t('Centigrade based display')}</option>
              <option value="false">{t('Fahrenheit based display')}</option>
            </select>
          </div>
          <fieldset className="field-set weather_details country_weather">
            <p>
              <a href="#edit-field-location1-body" className="panel-title fieldset-legend" data-toggle="collapse" aria-expanded="true">
                <T _str="Define Location" />
              </a>
            </p>
            <div className="help-block">
              <p>
                <T _str="To set a location for the map, click/drag the pin or marker of the map, or enter the latitude/longitude in the field provided." />
              </p>
            </div>
            <div className="form-item form-item-field-location1-lat form-type-textfield">
              <label className="control-label lat-label" htmlFor="edit-field-location1-lat">
                <T _str="Latitude" />
                <span className="form-required">*</span>
              </label>
              <input data-lat-delta="0"
                data-lat-field-id="1453"
                className="bing-map-field-watch-change form-control form-text required"
                type="text" id="edit-field-location1-lat"
                name="field_location1[lat]"
                value={formData.lat} size="60"
                maxlength="128"
                onChange={(e) => setFormData({ ...formData, lat: e.target.value })} />
            </div>
            <div className="form-item form-item-field-location1-lon form-type-textfield">
              <label className="control-label lon-label" htmlFor="edit-field-location1-lon">
                <T _str="Longitude" />
                <span className="form-required">*</span>
              </label>
              <input data-lng-delta="0"
                data-lng-field-id="1453"
                className="bing-map-field-watch-change form-control form-text required"
                type="text" id="edit-field-location1-lon"
                name="field_location1[0][lon]"
                value={formData.lon}
                size="60" maxlength="128"
                onChange={(e) => setFormData({ ...formData, lon: e.target.value })} />
            </div>
            <div className="livecontentmap">
              <T _str="Map Preview" />
              <div id="mapDiv" className='mt-2 mb-5'></div>
            </div>
          </fieldset>
          {userrole === 'country_admin' &&
            <div className="form-item form-item-field-assign-user-und form-type-select form-group mb-2">
              <label className="control-label assign-users-label mb-2" htmlFor="edit-field-assign-user-und">
                <T _str="Assign user" />
                <span className="form-required" title="This field is required.">*</span>
              </label>
              <select multiple="multiple"
                name="field_assign_user"
                className="form-control form-select required improvedselect-processed"
                id="edit-field-assign-user-und">
              </select>
            </div>
          }
          <div className="form-actions form-wrapper" id="edit-actions">
            <button type="submit" onClick={(e) =>
              handleFeedSubmit(e)} id="edit-submit" name="op" value="Save" className="btn btn-success form-submit icon-before">
              <span className="icon glyphicon glyphicon-ok" aria-hidden="true"></span>
              <T _str="Save" />
            </button>
            <button type="submit" onClick={(e) =>
              handleCancel()} id="edit-cancel" name="op" value="Cancel" className="btn btn-default form-submit icon-before">
              <span className="icon glyphicon glyphicon-remove" aria-hidden="true"></span>
              <T _str="Cancel" />
            </button>
            <button type="submit" id="edit-delete" name="op" value="Delete"
              className="btn btn-danger form-submit icon-before ms-2"
              onClick={() => { formData.nid = nid; setModalData(formData); setmodalShow(true); }}>
              <span className="icon glyphicon glyphicon-trash" aria-hidden="true"></span>
              <T _str="Delete" />
            </button>
            <Modal show={modalShow} onHide={modalHandleClose} className="topModal">
              <Deletelive livenid={modalData} closemodal={closemodal} />
            </Modal>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
}

export default EditLiveContent;
