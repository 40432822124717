/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: App.js
File Description: App file

Copyright 2021 - 2024 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import React, { useCallback, useEffect } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  Redirect,
} from "react-router-dom";
import SessionTimeout from "./Components/IdelTimeout/SessionTimeout";
import AccountDenied from "./Components/UserAccountPages/AccountDenied";
import AccountDisable from "./Components/UserAccountPages/AccountDisable";
import AccountPending from "./Components/UserAccountPages/AccountPending";
import SessionLimit from "./Components/UserAccountPages/SessionLimit";
import NotFoundPage from "./Layout/NotFoundPage";
import PageLayout from "./Layout/PageLayout";
import AddLiveContent from "./Pages/AddLiveContent";
import EditLiveContent from "./Pages/EditLiveContent";
import AddScrens from "./Pages/AddScrens";
import AddThemes from "./Pages/Themes/AddThemes";
import AddWeatherFeeds from "./Pages/AddWeatherFeeds";
import EditWeatherFeeds from "./Pages/EditWeatherFeeds";
import CalendarPage from "./Pages/CalendarPage";
import CMSLicense from "./Pages/CMSLicense";
import CreatePlaylist from "./Pages/CreatePlaylist/CreatePlaylist";
import Register from "./Pages/Register/Register";
import Dashboard from "./Pages/Dashboard";
import EditPlaylist from "./Pages/EditPlaylist/EditPlaylist";
import EditTheme from "./Pages/Themes/EditTheme";
import EditDevice from "./Pages/EditDevice";
import EviewLicense from "./Pages/EviewLicense";
import Groups from "./Pages/Groups";
import Library from "./Pages/Library";
import LiveContentManagement from "./Pages/LiveContentManagement";
import ManageUsers from "./Pages/ManageUsers";
import Playlists from "./Pages/Playlists/Playlists";
import Profile from "./Pages/Profile";
import Schedule from "./Pages/Schedule";
import ScreenCalendar from "./Pages/ScreenCalendar";
import SelectPlaylist from "./Pages/SelectPlaylist";
import ThemeList from "./Pages/Themes/ThemeList";
import EditDashboardDevice from "./Pages/EditDashboardDevice";
import Deviceslist from "./Pages/Deviceslist";
import AddDevice from "./Pages/AddDevice";
import MultiDeviceConfig from "./Pages/MultiDeviceConfig";
import AddUserMessages from "./Pages/UserMessages/AddUserMessages";
import AddNodeMedia from "./Pages/AddNodeMedia";
import Masq from "./Pages/Masq";
import Reset from "./Pages/Register/Reset";
import GetUserStatus from "./utils/GetUserStatus";
import UnitsReport from "./Pages/UnitsReport";
import ManageMercuryUsers from "./Pages/AddMercuryUser"; 

function App() {
  const history = useHistory();
  const location = useLocation();
  var token = localStorage.getItem("userdetails");
  var status = token && JSON.parse(token).user_status;

  const profileRedirection = useCallback(() => {
    if (status === "2" && location.pathname !== "/profile") {
      history.push("/profile");
    }
  }, [location]);

  useEffect(() => {
    GetUserStatus();
    profileRedirection();
  }, [profileRedirection]);

  return (
    <>
      {window.location.pathname !== '/register' && window.location.pathname !== '/reset'? (<SessionTimeout />):null }
      <PageLayout>
        <Switch>
          <Route path="/" exact>
            <Dashboard />
          </Route>
		      <Route path="/reset">
            <Reset />
          </Route>	
          <Route path="/groups">
            <Groups />
          </Route>
          <Route path="/playlist">
            <Playlists />
          </Route>
          <Route path="/media">
            <Library />
          </Route>
          <Route path="/calendar">
            <CalendarPage />
          </Route>
          <Route path="/profile/:uid?">
            <Profile />
          </Route>
          <Route path="/select-playlist">
            <SelectPlaylist />
          </Route>
          <Route path="/add-screens">
            <AddScrens />
          </Route>
          <Route path="/schedule">
            <Schedule />
          </Route>
          <Route path="/screen-calendar">
            <ScreenCalendar />
          </Route>
          <Route path="/account/pending">
            <AccountPending />
          </Route>
          <Route path="/MultiDeviceConfig">
            <MultiDeviceConfig />
          </Route>
          <Route path="/account/disable">
            <AccountDisable />
          </Route>
          <Route path="/account/denied">
            <AccountDenied />
          </Route>
          <Route path="/session/limit">
            <SessionLimit />
          </Route>
          <Route
            path="/screen/:id"
            render={(props) => (
              <EditDashboardDevice id={props.match.params.id} />
            )}
          />
          {/* Country Admin pages*/}
          <Route path="/manage-users">
            <ManageUsers />
          </Route>
		   <Route path="/add-mercury-user">
            <ManageMercuryUsers />
          </Route>
          <Route path="/add-live-content">
            <AddLiveContent />
          </Route>
          <Route path="/live-content-management">
            <LiveContentManagement />
          </Route>
          <Route path="/add-themes">
            <AddThemes />
          </Route>
          <Route
            path="/theme/:id"
            render={(props) => <EditTheme id={props.match.params.id} />}
          />
          <Route
            path="/deviceconfiguration/:id"
            render={(props) => <EditDevice id={props.match.params.id} />}
          />
          <Route
            path="/edit-live-content/:id"
            render={(props) => <EditLiveContent id={props.match.params.id} />}
          />
          <Route
            path="/edit-weather-feeds/:id"
            render={(props) => <EditWeatherFeeds id={props.match.params.id} />}
          />
          <Route path="/theme-list">
            <ThemeList />
          </Route>
          <Route path="/deviceslist">
            <Deviceslist />
          </Route>
          <Route path="/units-report">
            <UnitsReport />
          </Route>
          <Route path="/add/media">
            <AddUserMessages />
          </Route>
          {/* This is for video and can be removed later. */}
          <Route path="/test/media">
            <AddNodeMedia />
          </Route>

          {/* Building manger pages*/}
          <Route path="/add-weather-feeds">
            <AddWeatherFeeds />
          </Route>
          <Route path="/live-content-management">
            <LiveContentManagement />
          </Route>
          <Route path="/create-playlist">
            <CreatePlaylist />
          </Route>
          <Route path="/register">
            <Register />
          </Route>	  
          <Route path="/edit-playlist">
            <EditPlaylist />
          </Route>
          <Route path="/cms-license">
            <CMSLicense />
          </Route>
          <Route path="/eview-license">
            <EviewLicense />
          </Route>

          {/* Super Admin pages */}
          <Route path="/add-device">
            <AddDevice />
          </Route>
          <Route path="/standalone" render={() => <Redirect to="/" />} />
		  <Route path="/masq">
            <Masq/>
          </Route>
		  <Route path="*">
		    <NotFoundPage />
		  </Route>
        </Switch>
      </PageLayout>
    </>
  );
}

export default App;