import './FloatHeader.css';
import { T,useT } from '@transifex/react';
import { t } from "@transifex/native";
function FloatHeader() {
  const flag = (localStorage.getItem('parentuser'))?JSON.parse(localStorage.getItem('parentuser')).id:''
  const msg = useT('You are accessing %user account').replace('%user',localStorage.getItem('imp_username'))
  const handleReturnUser=()=>{
    localStorage.setItem('userdetails',localStorage.getItem('parentuser'))
    localStorage.setItem('imp_user',null)
    localStorage.setItem('imp_username','')
    localStorage.setItem('unmask',1)
  }

  return (
    (flag)?
    <div>
      <div class="masquerade-switch-back" id="admin-sticky-message">
        <div class="admin-sticky-inner">
       <div class="admin-sticky-inner-text">{msg}</div>
        <ul class="links"><li class="menu-17077 first last"><a href="/masq" onClick={handleReturnUser}><T _str="Return to my account" /></a></li></ul>
        </div>
      </div>
    </div>
    : <div></div>
  );
}

export default FloatHeader;