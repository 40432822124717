/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: ThemeList.js
File Description: Lists out the themes available in CMS portal

Copyright 2022,2023 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import { useState, useEffect } from 'react';
import { T, useT } from '@transifex/react';
import { t } from "@transifex/native"
import axios from 'axios';
import config from '../../utils/config';
import Pagination from '../../Components/Common/Pagination';
import LoadingOverlay from "react-loading-overlay";
import { confirmAlert } from 'react-confirm-alert';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom";
import { Buffer } from 'buffer';

function ThemeList(props) {
  // Variables and States
  const tabTitle = useT('Theme List');
  const [themesData, setThemesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [themesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();

  // Get logged in user details
  let userDetails = localStorage.getItem("userdetails");
  let cid = '';
  let loggedInUserID = '';
  let userrole = 'building_manager';
  if (userDetails !== null) {
    loggedInUserID = JSON.parse(userDetails).id;
    cid = JSON.parse(userDetails).cid;
    var userdetails = JSON.parse(localStorage.getItem('userdetails'));
    userdetails.roles.forEach(role => {
      if (role === "country_admin") {
        userrole = "country_admin";
      }
      if (role === "super_admin") {
        userrole = "super_admin";
      }
    });
  }

  const notify = (val) => {
    toast.error(val, {
      toastId: loggedInUserID
    });
  }

  const notifySuccess = (val) => {
    toast.success(val, {
      toastId: loggedInUserID
    });
  }

  useEffect(() => {
    document.title = tabTitle + ' | CMS';
  }, [tabTitle])

  useEffect(() => {
    if (location.theme_title !== undefined && location.theme_title !== "") {
      notifySuccess(t("@type %title has been created.").replace('@type', t('Themes')).replace('%title', location.theme_title));
    }

    if (location.theme_u_title !== undefined && location.theme_u_title !== "") {
      notifySuccess(t("@type %title has been updated.").replace('@type', t('Themes')).replace('%title', location.theme_u_title));
    }

    // Fetch Themes
    const fetchThemes = async () => {
      setLoading(true);
      var fetchURL = '';
      if (userrole === 'building_manager') {
        fetchURL = config.drupal_url + '/api/bm/themes?auth_uid=' + loggedInUserID + '&assign_uid=' + loggedInUserID; 
      } else {
        fetchURL = config.drupal_url + '/api/themes?cid=' + cid;
      }
      await axios.get(fetchURL, { withCredentials: true })
        .then(response => {
          const themesResData = response.data.rows;
          themesResData.forEach((key, index) => {
            key.theme = key.theme.replace(/&amp;/g, '&');
          });
          setThemesData(themesResData);
          setLoading(false);
        })
        .catch(function (error) {
          notify(t("Please try later.If the error persists, please contact Otis support."));
          setLoading(false);
        })
    };
    fetchThemes();
  }, [])

  // Delete Theme
  const alertbox = (theme) => {
    confirmAlert({
      title: t('Are you sure you want to delete {title}', {
        title: theme.title,
        _escapeVars: true,
      }),
      message: t('This action cannot be undone.'),
      buttons: [
        {
          label: t('Delete'),
          onClick: () => {
            setLoading(true);
            axios.get(config.drupal_url + '/session/token').then(result => {
              if (200 === result.status) {
                const csrfToken = result.data;
                const checkThemeUrl = config.drupal_url + "/rest/api/post/check/theme?_format=hal_json";
                let data = {
                  'nid' : theme.nid
                };

                //let login = "admin";
                //let password = config.auth_password;
                //const encodedString = Buffer.from(`${login}:${password}`).toString('base64');
                const headers = {
                  "Content-Type": "application/hal+json",
                  withCredentials: true,
                  'X-CSRF-Token': csrfToken,
                }
                axios.post(checkThemeUrl, data, { headers })
                  .then(response => {
                    if (response.data.code === 204) {
                      notifySuccess(t("@type %title was successfully deleted.").replace("@type", t("Themes")).replace("%title", theme.title));
                      setLoading(false)
                      setTimeout(function () {
                        window.location.reload(false)
                      }, 3000);
                    } else if (response.data.code === 200) {
                      notify(t("Image(s) currently used in playlist(s) cannot be deleted."));
                      setLoading(false);
                    } else {
                      notify(t("Please try later.If the error persists, please contact Otis support."));
                      setLoading(false)
                    }
                  })
              }
            })
          }
        },
        {
          label: t('Cancel'),
          onClick: () => { return true }
        }
      ]
    });
  }

  // Search functionality
  const searchTheme = () => {
    var titleCondition = "";
    if (searchTerm === "") {
      titleCondition = '?cid=' + cid;
    } else {
      titleCondition = '?cid=' + cid + '&title1=' + searchTerm;
    }
    setLoading(true);
    axios.get(config.drupal_url + '/api/themes' + titleCondition, { withCredentials: true })
      .then(response => {
        const themesResData = response.data.rows;
        themesResData.forEach((key, index) => {
          key.theme = key.theme.replace(/&amp;/g, '&');
        });
        setThemesData(themesResData);
        setLoading(false);
      })
      .catch(function (error) {
        notify(t("Please try later.If the error persists, please contact Otis support."));
        setLoading(false);
      })
  }

  // Pagination settings
  const paginate = pageNumber => setCurrentPage(pageNumber);
  const indexOfLastPlaylist = currentPage * themesPerPage;
  const indexOfFirstPlaylist = indexOfLastPlaylist - themesPerPage;
  var themesList = '';
  if (themesData.length > 10) {
    themesList = themesData.slice(indexOfFirstPlaylist, indexOfLastPlaylist);
  } else {
    themesList = themesData;
  }

  return (
    <LoadingOverlay active={loading} spinner text={t('Processing')}>
      <ToastContainer />
      <div className="headerSection">
        <h1 className="pageTitle">
          eView<sup>&reg;</sup> - <T _str="Theme List" />
        </h1>
      </div>
      <div className="theme-list-wrapper">
        <div className="search-box">
          <div className="search-input-box">
            <input
              className="form-control
              theme-search-box"
              type="text"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="actions_div" id="actions_div">
            <button
              type="submit"
              value="Save"
              className="actions_button submit_form tl-search-buttion"
              onClick={searchTheme}
            ><T _str="Search" />
            </button>
          </div>
        </div>
        <div className="tl-table-wrapper">
          <table className="tl-table">
            <thead className="tl-table-head">
              <tr className="tl-table-head-row">
                <th className="tl-table-heading"><T _str="Theme Title" /></th>
                <th className="tl-table-heading"><T _str="Themes" /></th>
                <th className="tl-table-heading"><T _str="Edit" /></th>
                <th className="tl-table-heading"><T _str="Delete" /></th>
                <th className="tl-table-heading"><T _str="Status" /></th>
              </tr>
            </thead>
            <tbody className="tl-table-body">
              {themesList.length === 0 &&
                <tr><T _str="No Themes Found" /></tr>
              }
              {themesList.map((theme, index) => (
                <tr className="tl-table-body-row index" key={index}>
                  <td className="tl-table-data">{theme.title}</td>
                  <td className="tl-table-data"><img src={theme.theme} alt="Themes" /></td>
                  <>
                    {
                      (() => {
                        if (userrole === 'country_admin') {
                          return (
                            <>
                              <td className="tl-table-data">
                                <a href={'/theme/' + theme.nid}><T _str="edit" /></a>
                              </td>
                              <td className="tl-table-data">
                                <a href='#!' onClick={(key) => alertbox(theme)} ><T _str="delete" /></a>
                              </td>
                            </>
                          )
                        } else if (userrole === 'building_manager' && theme.uid === loggedInUserID) {
                          return (
                            <>
                              <td className="tl-table-data">
                                <a href={'/theme/' + theme.nid}><T _str="edit" /></a>
                              </td>
                              <td className="tl-table-data">
                                <a href='#!' onClick={(key) => alertbox(theme)} ><T _str="delete" /></a>
                              </td>
                            </>
                          )
                        } else {
                          return (
                            <>
                              <td className="tl-table-data"></td>
                              <td className="tl-table-data"></td>
                            </>
                          )
                        }
                      })()
                    }
                  </>
                  <td className="tl-table-data"><T _str={theme.status} /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {themesData.length > 10 &&
          <Pagination
            itmesPerPage={themesPerPage}
            totalItems={themesData.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        }
      </div>
    </LoadingOverlay>
  );
}

export default ThemeList;
